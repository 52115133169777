footer.sticky-footer {
  padding: 2rem 0;
  flex-shrink: 0;
  border-top: 1px solid $gray-200;
  margin-top: 20px;
  .copyright {
    line-height: 1;
    font-size: 0.8rem;
  }
  img{
    width: 100%;
    height: auto;
  }
  .icons{
    display: flex;
    .icon{
      width: 35px;
      height: 35px;
      margin-left: 20px;
      margin-right: 20px;
      border: 2px solid $keppaBlack;
      background-color: $keppaBlack;
      border-radius: 35px;
      cursor: pointer;
      a{
        line-height: 1.70;
        color: $keppaColor;
        font-size: 20px;
      }
      &:hover{
        background-color: $keppaColor;
        a{
          color: $keppaBlack;
        }
      }
    }
  }
}

body.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }
}
