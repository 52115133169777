.package-teaser{
    width: 100%;
    border-radius: $border-radius;
    padding: 5px;
    border: 1px solid $gray-300;
    position: relative;
    
    &:hover{
        -webkit-box-shadow:0px 0px 15px 1px $keppaColor75;
        -moz-box-shadow: 0px 0px 15px 1px $keppaColor75;
        box-shadow: 0px 0px 15px 1px $keppaColor75;
    }
    img{
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: $border-radius;
    }
    .caption{
        padding: 10px;
        h4 {
            font-weight: 400;
            padding-bottom: 0px;
            margin-bottom: 0px;
            overflow: hidden;
            white-space: normal;
            height: 58px;
        }
        p{
            margin-bottom: 0px;
            font-size: small;
            i{
                color: $keppaColorDark;
            }
            &.destination{
                overflow: hidden;
                white-space: normal;
                height: 40px;
            }
        }
        a{
            margin-top: 5px;
            color: $keppaColorDark;
            &:hover{
                color: $white;
            }
        }
    }
    .package-label{
        color: $white;
        position: absolute;
        top: 20px;
        right: 5px;
        padding: 3px 10px;
        opacity: 0.8;
    }
}

.pack-content{
    h4 i,h5 i,p i{
        color: $keppaColorDark;
    }
    .card{
        .btn-link{
            color: $keppaColorDark;
            text-decoration: none;
            &:hover, &:focus{
                color: $keppaColor;
                box-shadow: none;
            }
        }
    }
}

.package-slider{
    padding-top: 10px;
    .package-item{
        height: auto;
        margin: 5px 10px;
    }
}