// Custom Card Styling

.card {
  .card-header {
    // Format Dropdowns in Card Headings
    .dropdown {
      line-height: 1;
      .dropdown-menu {
        line-height: 1.5;
      }
    }
  }
  // Collapsable Card Styling
  .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;
    padding: 0.75rem 3.25rem 0.75rem 1.25rem;
    &::after {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 51px;
      font-weight: 900;
      content: '\f107';
      font-family: 'Font Awesome 5 Free';
      color: $gray-400;
    }
    &.collapsed {
      border-radius: $card-border-radius;
      &::after {
        content: '\f105';
      }
    }
  }
  &.clip-card{
    cursor: pointer;
    .card-body{
      padding: 0.5rem;
      p{
        margin-bottom: 0.3rem;
      }
    }
    &.clip-card-primary{
      &:hover{
        border-color: $primary;
      }
      &.selected{
        border-color: $primary;
        border-width: 2px;
      }
    }
    &.clip-card-secondary{
      &:hover{
        border-color: $secondary;
      }
      &.selected{
        border-color: $secondary;
        border-width: 2px;
      }
    }
    &.clip-card-success{
      &:hover{
        border-color: $success;
      }
      &.selected{
        border-color: $success;
        border-width: 2px;
      }
    }
    &.clip-card-info{
      &:hover{
        border-color: $info;
      }
      &.selected{
        border-color: $info;
        border-width: 2px;
      }
    }
    &.clip-card-warning{
      &:hover{
        border-color: $warning;
      }
      &.selected{
        border-color: $warning;
        border-width: 2px;
      }
    }
    &.clip-card-danger{
      &:hover{
        border-color: $danger;
      }
      &.selected{
        border-color: $danger;
        border-width: 2px;
      }
    }
    &.clip-card-dark{
      &:hover{
        border-color: $dark;
      }
      &.selected{
        border-color: $dark;
        border-width: 2px;
      }
    }
  }
}
