// Global component styles
body {
  background: $white;
  color: $keppaBlack;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../fonts/Poppins-Bold.ttf) format('truetype');
}

.text-poppins-bold{
  font-family: 'Poppins' !important;
}

a {
  color: $keppaColor;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
    color: $keppaColorDark;
  }
}

.no-display {
  display: none !important;
}

.no-visible {
  visibility: hidden !important;
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: $white;
  background: fade-out($keppaColor, .5);
  line-height: 46px;
  &:focus,
  &:hover {
    color: $white;
  }
  &:hover {
    background: $keppaColor;
  }
  i {
    font-weight: 800;
  }
}

.whatsapp-top {
  position: fixed;
  left: 1rem;
  bottom: 50%;
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  color: $white;
  background: fade-out($brand-whatsapp, .5);
  &:focus,
  &:hover {
    color: $white;
  }
  &:hover {
    background: $brand-whatsapp;
  }
  i {
    font-weight: 800;
  }
}

.global-alert{
	position: fixed;
	top: 50px;
	right: 50px;
	z-index: 99999;
	.close{
		cursor: pointer;
		margin-left: 20px;
	}
}

.progress{
	.progress-bar{
		width: 100%;
	}
}

.main-content{
  padding-top: 100px;
}

.main-header{
  min-height: 500px;
  margin-top: 75px;
  .heading{
    bottom: 200px;
    position: absolute;
    width: 50%;
    padding-top: 10px;
    border-radius: 0.5rem;
    background-color: $keppaColor50;
    letter-spacing: 1px;
    font-size: 2.5rem;
  }
  .carousel-slider{
      .slider-wrapper{
          .slider{
              li.slide{
                  img{
                      height: 500px;
                      object-fit: cover;
                  }
              }
          }
      }
  }
  .thumbs-wrapper{
    margin-bottom: 0px;
    ul.thumbs{
      text-align: center;
      margin-bottom: 0px;
      .thumb.selected{
        border: 3px solid $keppaColor;
      }
      .thumb:hover{
        border: 3px solid $keppaColor50;
      }
    }
  }
}

.search-container{
  bottom: 200px;
  position: absolute;
  width: 50%;
  border-radius: $border-radius;
  background-color: $keppaColor75;
  .input-container{
    position: relative;
    width: 100%
  }
  input{
    height: 50px;
  }
  i{
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 25px;
    color: $keppaColor;
    cursor: pointer;
  }
  &.small{
    position: relative;
    bottom: unset;
    width: auto;
    margin-right: 10px;
    background-color: transparent;
    input{
      height: auto;
    }
    i{
      font-size: 15px;
      top: 10px;
    }
  }
  h1{
    font-weight: 900;
    font-family: serif;
    letter-spacing: 1px;
    font-size: 2.5rem;
    padding-top: 20px;
    color: $white;
  }
  h4{
    letter-spacing: 1px;
    padding-bottom: 20px;
    color: $white;
    font-size: 1.25rem;
  }
}

.faq-container{
  i{
    color: $keppaColorDark;
  }
}

.react-multi-carousel-list{
  .react-multiple-carousel__arrow{
    color: $keppaColorDark;
    &::before{
      color: $keppaColorDark;
    }
    &:focus{
      outline: none;
    }
  }
}

.association-img{
  width: 100%;
}

@include media-breakpoint-down(lg) {
  .search-container{
    width: 60%;
    &.small{
      margin-left: 10px;
    }
    h1{
      font-size: 2rem;
    }
    h4{
      font-size: 1rem;
    }
  }
  .main-header{
    .heading{
      width: 60%;
      font-size: 2rem;
    }
  }
}


@include media-breakpoint-down(sm) {
  .search-container{
    width: 90%;
    h1{
      font-size: 1.5rem;
    }
    h4{
      font-size: 1rem;
    }
  }
  .main-header{
    .heading{
      width: 90%;
      font-size: 1.5rem;
    }
  }
}

.text-black{
  color: $black !important;
}

.text-keppa{
  color: $keppaColorDark !important;
}

#enquiryModal, #enquiryMsgModal{
  .modal-header{
    background-color: $keppaColor;
    justify-content: center !important;
    color: $white;
    .modal-title{
      width: 100%;
      font-size: 1.5rem;
    }
    .btn-link{
      color: $white;
      text-decoration: none;
      font-size: 1.5rem;
    }
  }
}

.enquiry-form{
  .card-header{
    background-color: $keppaColor;
  }
}

.pack-content{
  .accordion{
    .card-body{
      > div {
        padding: 0 0.75rem;
      }
    }
  }
}
