.dashboard-content{
    a {
        color: inherit;
        &:focus {
          outline: none;
        }
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
    .showcase-carousel{
        margin-top: -50px;
        .item{
            height: 250px;
            color: $white;
            border-radius: $border-radius;
            margin-left: 10px;
            margin-right: 10px;
            a{
                text-decoration: none;
            }
            img{
                height: 180px;
                width: 100%;
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
                object-fit: cover;
            }
            .caption{
                padding: 5px;
                h4{
                    margin-bottom: 2px;
                    font-size: 1.5rem;
                }
                p{
                    color: $white;
                    text-decoration: none;
                    font-size: 0.8rem;
                }
            }
        }
    }
    .dashboard-section{
        padding-top: 25px;
        padding-bottom: 25px;
        .section-heading{
            font-weight: 700;
        }
        .section-link{
            text-decoration: none;
        }
        .btns{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            scrollbar-width: none;
            .btn{
                margin-right: 1rem;
                padding: 0.5rem 1rem;
                font-size: 1.25rem;
                border-radius: 0.3rem;
                white-space: nowrap;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .dashboard-content{
        .dashboard-section .btns{
            .btn{
                margin-right: 0.5rem;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                border-radius: 0.5rem;
            }
        }
        .showcase-carousel{
            .item{
                .caption{
                    h4{
                        font-size: 1.10rem;
                    }
                    p{
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .dashboard-content{
        .dashboard-section .btns{
            .btn{
                margin-right: 0.1rem;
                padding: 0.25rem 0.5rem;
                font-size: 0.875rem;
                border-radius: 0.2rem;
            }
        }
    }
}