.navbar{
    background-color: $white;
    .navbar-brand{
        img{
            height: 85px;
            transition: height 0.5s ease-in;
            //position: absolute;
            top: 5px;
            &.branded{
                height: 110px;
                //position: absolute;
                top: 0px;
            }
        }
    }
    .navbar-nav{
        li.nav-item{
            margin-right: 25px;
            height: 60px;
            margin-top: 20px;
            .dropdown-menu-lg{
                transition: all 5s ease-in-out;
                border-radius: $border-radius;
                background-color: $white;
                position: fixed;
                z-index: $zindex-dropdown;
                left: 0;
                top: 80px;
                border-bottom: 1px solid $gray-200;
                .category-item{
                    max-width: 300px;
                    p{
                        margin-bottom: 0.5rem;
                    }
                }
                .thumbnail-item{
                    img{
                        height: 80px;
                        width: 100%;
                        object-fit: cover;
                        border-radius: $border-radius;
                    }
                }
                .nav-link{
                    padding-top: 0px;
                }
            }
            .dropdown-menu-sm{
                border-radius: $border-radius;
                min-width: 300px;
                background-color: $white;
                position: absolute;
                z-index: $zindex-dropdown;
                .category-item{
                    max-width: 300px;
                }
                .nav-link{
                    padding-top: 0px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar{
        .navbar-brand{
            img{
                height: 65px;
            }
        }
        .navbar-nav{
            li.nav-item{
                .dropdown-menu-container{
                    max-height: 80vh;
                    overflow-x: auto;
                }
                .dropdown-menu-lg{
                    position: relative;
                    .category-item{
                        max-width: none;
                    }
                }
            }
        }
        .btn-keppa{
            margin-left: 20px;
            width: 90%;
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar{
        .navbar-brand{
            img{
                position: relative;
            }
        }
        .navbar-nav{
            li.nav-item{
                height: auto;
                margin-top: 0px;
                margin-left: 10px;
                .dropdown-menu-lg{
                    top: auto;
                    .category-item{
                        max-width: none;
                    }
                    .thumbnail-item{
                        img{
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .navbar{
        .navbar-brand{
            img{
                position: relative;
            }
        }
        .navbar-nav{
            li.nav-item{
                height: auto;
                margin-top: 0px;
                margin-left: 10px;
                .dropdown-menu-lg{
                    top: auto;
                    .category-item{
                        max-width: none;
                    }
                    .thumbnail-item{
                        img{
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}